<template>
  <video-player
    :ref="'videoPlayer' + _uid + data.id"
    :options="options"
    class="vjs-custom-skin"
    @play="onPlayerPlay($event)"
    @ready="onPlayerReady($event)"
    @timeupdate="onPlaying()"
  />
</template>

<script>
import { videoPlayer } from "@thelak/vue-videojs7";
// import "videojs-youtube";

export default {
  name: "VideoPlayer",
  components: { videoPlayer },
  props: {
    data: Object,
    height: Number,
    isToCard: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isPlayingTimeout: null
    };
  },
  computed: {
    options() {
      return {
        autoplay: true,
        controls: true,
        sources: this.data.sources,
        poster: this.data.poster,
        height: this.data.height,
        preload: "metdata",
        controlBar: {
          pictureInPictureToggle: false
        },
        plugins: {
          videoJsResolutionSwitcher: {
            dynamicLabel: true,
            default: "high"
          }
        },
        playbackRates: [0.75, 1, 1.5, 2],
        fluid: true,
        language: "ru"
      };
    },
    player() {
      return this.$refs["videoPlayer" + this._uid + this.data.id].player;
    }
  },
  methods: {
    onPlayerPlay() {
      if (this.isToCard) {
        this.player.pause();
      } else {
        this.addHistory();
      }
    },
    onPlaying() {
      if (this.$auth.check()) {
        const time = this.player.currentTime();
        if (time && !(Math.trunc(time) % 5) && !this.isPlayingTimeout) {
          this.isPlayingTimeout = setTimeout(() => {
            this.axios
              .post("/video/timecode/add", null, {
                params: {
                  videoId: this.data.id,
                  timecode: time
                }
              })
              .then(() => {
                this.isPlayingTimeout = null;
              });
          }, 1000);
        }
      }
    },
    onPlayerReady() {
      this.getTimeCode();
    },
    getTimeCode() {
      if (this.$auth.check()) {
        this.axios
          .get("/video/timecode/get", {
            params: {
              videoId: this.data.id
            }
          })
          .then(response => {
            this.test = response.data;
            this.player.currentTime(parseInt(response.data));
          });
      }
    },
    addHistory() {
      if (this.$auth.check()) {
        this.axios.post("/video/history/add", null, {
          params: {
            videoId: this.data.id
          }
        });
      }
    }
  },
  mounted() {
    if (this.player) {
      this.player.reset();
    }
  }
};
</script>

<style lang="scss">
video[poster],
.vjs-tech {
  object-fit: cover;
}

.vjs-poster {
  background-position: inherit;
  background-size: cover;
}
.tovar-items-img {
  .video-js .vjs-tech {
    height: auto;
  }
  .vjs-icon-play:before,
  .video-js .vjs-play-control .vjs-icon-placeholder:before,
  .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    content: " ";
  }
}

.vjs-resolution-button .vjs-resolution-button-label {
  line-height: 2;
}

.vjs-resolution-button .vjs-menu {
  bottom: 0;
}
.video-js .vjs-control {
  font-weight: bold;
  color: #fff !important;
}

.video-js .vjs-big-play-button {
  display: none;
}
.video-js .vjs-control-bar {
  display: flex;
}
</style>
