<template>
  <div class="wrapper-inner">
    <div class="inner-content" v-if="data">
      <h1 class="header-big">{{ data.title }}</h1>
      <video-detail-block :update-video="getVideo" :data="data" />
      <media-block
        :media="theVideos"
        :update-media="loadPlaygroundVideos"
        block-head="Видео"
        block-class="inner-news-tovar"
      />
      <video-slider-block
        :media="theSpeakerVideos"
        block-class="modul-video-other"
        block-head="Смотрите также"
        v-if="theSpeakerVideos.length"
      />
    </div>
  </div>
</template>

<script>
import VideoDetailBlock from "@/components/page/media/VideoDetailBlock";
import VideoSliderBlock from "@/components/page/media/VideoSliderBlock";
import MediaBlock from "@/components/page/media/MediaBlock";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Video",
  components: { MediaBlock, VideoDetailBlock, VideoSliderBlock },
  data() {
    return {
      data: null,
      page: 1
    };
  },
  computed: {
    ...mapGetters({
      videos: "speakerVideos"
    }),
    ...mapGetters(["playgroundVideos"]),
    theVideos() {
      return this.playgroundVideos.filter(
        x => x.video.id !== parseInt(this.$route.params.id)
      );
    },
    theSpeakerVideos() {
      return this.videos.filter(
        x => x.video.id !== parseInt(this.$route.params.id)
      );
    }
  },
  methods: {
    ...mapActions(["getPageVideos"]),
    async getVideo(id) {
      const response = await this.$http.get("/video/get", {
        params: { id: id }
      });
      const data = response.data;
      if (data.subscription) {
        const user = this.$auth.user();
        if (
          !data.sources ||
          !user ||
          !user.isSubscribe ||
          new Date().getTime() > new Date(user.subscriptionDate).getTime()
        ) {
          await this.$router.push("/library");
        }
      }
      this.data = data;
    },
    loadVideos() {
      const search =
        this.data.speaker && this.data.speaker.length
          ? this.data.speaker[0].name
          : null;
      this.getPageVideos({
        type: "speakerVideos",
        method: "/video/search",
        params: {
          page: this.page,
          search
        }
      }).then(() => {
        this.page++;
      });
    },
    loadPlaygroundVideos() {
      this.getPageVideos({
        type: "playgroundVideos",
        method: "/video/list",
        params: {
          page: this.page,
          playgroundFilter: this.data.playground
        }
      }).then(() => {
        this.page++;
      });
    },
    init() {
      this.getVideo(this.$route.params.id).then(() => {
        this.loadVideos();
        this.loadPlaygroundVideos();
      });
    }
  },
  created() {
    this.init();
  },
  watch: {
    "$route.params.id"(to, from) {
      if (to !== from) {
        this.page = 1;
        this.init();
      }
    }
  }
};
</script>

<style scoped></style>
