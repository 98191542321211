<template>
  <site-section section-class="modul-video-detail">
    <template v-slot:content>
      <div class="modul-video-player">
        <video-player :height="530" :data="video" />
      </div>
      <div class="modul-video-bottom-flex">
        <div class="modul-video-bottom-left">
          <span>
            <img src="@/assets/img/eye.svg" alt="Views" /> {{ video.views }}
          </span>
          <span
            id="bookmark"
            @click="updateBookmarked"
            @mouseenter="mouseOver = true"
            @mouseleave="mouseOver = false"
            :class="{ 'is-bookmarked': isBookmarked }"
            v-if="isAuth"
          >
            <i
              :class="[
                {
                  fas: isBookmarked || mouseOver,
                  far: !isBookmarked && !mouseOver
                },
                'fa-bookmark'
              ]"
            ></i>
            {{ isBookmarked ? "Добавлено" : "Посмотреть позже" }}
          </span>
        </div>
        <div class="modul-video-bottom-right">
          <tags :tags="data.category" />
        </div>
      </div>
      <div class="modul-video-pre-text">
        <rating-item
          :item-id="data.id"
          check-link="/video/rating/check"
          delete-link="/video/rating/delete"
          set-link="/video/rating/add"
          param-name="videoId"
          :rating="data.rating"
          :update-item="updateVideo"
        />
        <ul class="modul-video-parametrs">
<!--           <li>
            Площадка: <span>{{ data.playground }}</span>
          </li> -->
          <li>
            Спикер:
            <router-link
              v-for="speaker in data.speaker"
              v-bind:key="speaker.id"
              style="color: #fff; margin-right: 3px;"
              :to="{ name: 'SingleSpeaker', params: { id: speaker.id } }"
            >
              <span v-html="speaker.name"></span>
            </router-link>
          </li>
          <li>Страна: <span v-html="data.country"></span></li>
          <li>Язык: <span v-html="data.language"></span></li>
        </ul>
        <p v-html="data.description"></p>
<!--         <p class="dates">
          Дата публикации: <i class="far fa-calendar"></i>
          {{ data.createdDate | moment("calendar") }} г.
        </p> -->
      </div>
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
import VideoPlayer from "@/components/page/video/VideoPlayer";
import Tags from "@/components/page/items/tags/Tags";
import RatingItem from "@/components/page/rating/RatingItem";
export default {
  name: "VideoDetailBlock",
  components: { Tags, VideoPlayer, SiteSection, RatingItem },
  props: {
    data: Object,
    updateVideo: Function
  },
  data() {
    return {
      isBookmarked: false, // todo save to vuex
      mouseOver: false,
      videoKey: 0
    };
  },
  methods: {
    updateBookmarked() {
      if (this.isBookmarked) {
        this.deleteBookmarked();
      } else {
        this.setBookmarked();
      }
    },
    setBookmarked() {
      this.axios
        .post("/video/favorites/add?videoId=" + this.data.id)
        .then(data => {
          this.isBookmarked = data.data;
        });
    },
    deleteBookmarked() {
      this.axios
        .delete("/video/favorites/delete?videoId=" + this.data.id)
        .then(data => {
          this.isBookmarked = !data.data;
        });
    },
    checkBookmarked() {
      this.axios
        .get("/video/favorites/check", {
          params: { videoId: this.data.id }
        })
        .then(data => {
          this.isBookmarked = data.data;
        });
    }
  },
  mounted() {
    // console.log(this.videoKey);
    if (this.isAuth) {
      this.checkBookmarked();
    }
  },
  computed: {
    video() {
      const video = this.data;
      return {
        length: video.duration,
        views: video.viewsCount,
        vote: video.rating + "/10",
        id: video.id,
        poster: video.coverUrl,
        sources: video.sources
      };
    },
    isAuth() {
      return this.$auth.check();
    }
  }
};
</script>

<style scoped>
.is-bookmarked {
  font-weight: 600;
}
#bookmark {
  cursor: pointer;
}
#bookmark:hover {
  font-weight: 600;
}
</style>
